import { PropsWithChildren, useEffect, useRef } from "react";

import heroImageSrc from "../assets/images/s2.jpg";
import aboutUsImageSrc from "../assets/images/2.jpg";
import { useIsInViewport } from "../hooks/useIsInViewport";
import BookNowButton from "../components/BookNowButton";
import { NavLink } from "react-router-dom";

interface HomePageProps {
  data?: string;
}

const HomePage = (props: PropsWithChildren<HomePageProps>) => {
  const heroRef = useRef(null);

  const isHeroInViewport = useIsInViewport(heroRef, { threshold: 0.85 });

  useEffect(() => {
    return () => {
      const navbar = document.querySelector(".navbar");
      if (navbar) {
        navbar.classList.add("bg-slate-600");
        navbar.classList.remove("bg-transparent");
      }
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      if (isHeroInViewport) {
        navbar.classList.remove("bg-slate-600");
        navbar.classList.add("bg-transparent");
      } else {
        navbar.classList.add("bg-slate-600");
        navbar.classList.remove("bg-transparent");
      }
    }
  }, [isHeroInViewport]);

  return (
    <>
      <div
        className="hero min-h-screen text-amber-200"
        style={{
          backgroundImage: `url("${heroImageSrc}")`,
        }}
      >
        <div className="hero-overlay" ref={heroRef}></div>
        <div className="hero-content text-center text-neutral-content text-amber-200">
          <div className="w-full">
            <h1 className="mb-5 text-2xl md:text-4xl font-bold">
              Reach the hidden gems of Corfu with ease
            </h1>
            <div>Experience the island with convenience.</div>
            <div className="mb-5">
              Book your airport transfer or personalized trip.
            </div>
            <BookNowButton text={"Book Here"} />
          </div>
        </div>
      </div>
      <div className="bg-stone-50">
        <div className="w-11/12 mx-4 flex flex-col lg:flex-row lg:justify-center gap-4 lg:gap-0 py-5 px-2 md:px-10">
          <img
            className="aspect-video h-80 object-contain"
            src={aboutUsImageSrc}
            alt="About Us"
          />
          <div className="flex flex-col justify-center lg:w-96">
            <h1 className="font-bold uppercase mb-3">
              Explore the island with us
            </h1>
            <div className="mb-4">
              We offer private transfers to/from Corfu airport, personalized
              trips to most famous destinations on the island. We provide
              visitors with a convenient and reliable way to travel.
            </div>
            <div>
              <NavLink to="/about-us">
                <button className="btn bg-stone-700 btn-lg uppercase text-white font-bold border rounded-full">
                  Learn more about us
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
