import { NavLink } from "react-router-dom";

import logoSrc from "../assets/images/Artboard 9.png";
import BookNowButton from "./BookNowButton";

const pages = [
  { title: "Home", path: "/" },
  { title: "About", path: "/about-us" },
  // { title: "Contact Us", path: "/contact-us" },
];

function ResponsiveAppBar() {
  const defaultNavItemClasses =
    "p-5 text-lg uppercase hover:bg-slate-900 rounded-full";

  return (
    <div className="navbar fixed top-0 z-10 text-white bg-stone-700">
      <div className="navbar-start py-5 grow-0">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 bg-stone-700"
          >
            {pages.map((p) => (
              <NavLink
                key={p.path}
                style={{ textDecoration: "none" }}
                to={p.path}
                className={({ isActive }) =>
                  `${isActive ? "active " : ""}${defaultNavItemClasses}`
                }
              >
                {p.title}
              </NavLink>
            ))}
            {/* <BookNowButton key={"navbar-book-now-button"} /> */}
          </ul>
        </div>
        <NavLink
          className="btn bg-transparent hover:bg-transparent uppercase p-0 md:p-1 text-normal md:text-xl border-0"
          to="/"
        >
          <img
            src={logoSrc}
            height={36}
            alt="Corfu Gem Logo"
            className="w-28 md:w-48"
          />
        </NavLink>
      </div>
      <div className="navbar-end">
        <ul className="menu menu-horizontal px-1 hidden lg:flex">
          {pages.map((p) => (
            <NavLink
              key={p.path}
              style={{ textDecoration: "none" }}
              to={p.path}
              className={({ isActive }) =>
                `${isActive ? "active " : ""}${defaultNavItemClasses}`
              }
            >
              {p.title}
            </NavLink>
          ))}
        </ul>
        <BookNowButton key={"navbar-book-now-button"} />
      </div>
    </div>
  );
}
export default ResponsiveAppBar;
