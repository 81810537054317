import { FunctionComponent } from "react";

interface InputControllErrorProps {
  text: string;
}

const InputControllError: FunctionComponent<InputControllErrorProps> = (
  props
) => {
  return (
    <div role="alert" className="text-red-400 font-bold">
      {props.text}
    </div>
  );
};

export default InputControllError;
