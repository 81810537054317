import { FunctionComponent } from "react";

interface InputLabelProps {
  label: string;
  isRequired: boolean;
}

const InputLabel: FunctionComponent<InputLabelProps> = (props) => {
  const { label, isRequired } = props;

  return (
    <label className="label">
      <span className="label-text text-stone-700">
        {label}
        {isRequired && <span className="text-red-500"> *</span>}
      </span>
    </label>
  );
};

export default InputLabel;
