import * as crypto from "@aws-crypto/sha256-js";
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { Auth } from "@aws-amplify/auth";

const { Sha256 } = crypto;
const endpoint = new URL(
  "https://quz5227v75fj5mnxtit2pqwgpe0dwcpn.lambda-url.us-east-1.on.aws/"
);

export const bookRental = async (formValues: any) => {
  const signer = new SignatureV4({
    credentials: await Auth.currentCredentials(),
    region: "us-east-1",
    service: "lambda",
    sha256: Sha256,
  });

  const requestToBeSigned = new HttpRequest({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      host: endpoint.host,
    },
    hostname: endpoint.host,
    body: JSON.stringify(formValues),
    path: endpoint.pathname,
  });
  const signed = await signer.sign(requestToBeSigned);
  const request = new Request(endpoint.origin, signed);
  const response = await fetch(request);

  if (response.status !== 200) {
    throw new Error();
  }

  return;
};
