import AppFooter from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RootLayout() {
  return (
    <div className="App">
      <AppHeader></AppHeader>
      <main className="min-h-screen bg-stone-200">
        <Outlet />
      </main>
      <AppFooter />
      <ScrollRestoration />
      <ToastContainer position="bottom-center" autoClose={5000} theme="light" />
    </div>
  );
}

export default RootLayout;
