import { FunctionComponent, useEffect, useState } from "react";
import InputControl from "../components/InputControl";
import PageHeading from "../components/PageHeading";
import DatePickerControll from "../components/DatePickerControll";
import { useForm, useWatch } from "react-hook-form";
import InputControllError from "../components/InputControllError";
import bookingBannerImageUrl from "../assets/images/3-1.jpg";
import { bookRental } from "../common/clients/bookingClient";
import { toast } from "react-toastify";
import { convertToCompatibleDateString } from "../common/utils";

interface BookingPageProps {}

const BookingPage: FunctionComponent<BookingPageProps> = () => {
  const currentDate = new Date();
  const [isTwoWay, setIsTwoWay] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  interface Inputs {
    fullName: string;
    email: string;
    phone: string;
    pickUpDate: string;
    pickUpTime: string;
    pickUpPickUpPoint: string;
    pickUpDropOffPoint: string;
    returnDate?: string;
    returnTime?: string;
    returnPickUpPoint?: string;
    returnDropOffPoint?: string;
    notes?: string;
  }

  const {
    register,
    unregister,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
    control,
    reset,
  } = useForm<Inputs>({
    mode: "all",
  });

  const onSubmit = (data: any) => {
    setIsFormSubmitting(true);
    const payload = {
      returnDate: "",
      returnTime: "",
      returnPickUpPoint: "",
      returnDropOffPoint: "",
      ...data,
    };

    return bookRental(payload)
      .then(() => {
        toast.success(
          "Booking successfully sent. We will reach out to you for more details."
        );
        reset();
      })
      .catch((e: any) =>
        toast.error("Something went wrong! Please try again later.")
      )
      .finally(() => setIsFormSubmitting(false));
  };

  const pickUpDate = useWatch({ name: "pickUpDate", control });
  const returnDate = useWatch({ name: "returnDate", control });

  useEffect(() => {
    if (isTwoWay) {
      register("returnDate");
      register("returnTime");
      register("returnPickUpPoint");
      register("returnDropOffPoint");
    } else {
      unregister("returnDate");
      unregister("returnTime");
      unregister("returnPickUpPoint");
      unregister("returnDropOffPoint");
    }
  }, [isTwoWay, register, unregister]);

  return (
    <div className="flex flex-col pb-8" style={{ marginTop: "104px" }}>
      <img
        src={bookingBannerImageUrl}
        alt="Book Now"
        className="lg:h-80 md:w-auto object-contain"
      />
      <form
        className="mx-auto px-3 w-full md:w-3/5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PageHeading text="Book Your Transfer" />
        <div className="flex flex-row gap-4">
          <div className="form-control w-fit">
            <label className="label cursor-pointer">
              <span className="label-text pr-2 font-bold">One way</span>
              <input
                type="radio"
                name="radio-10"
                className="radio bg-white"
                checked={!isTwoWay}
                onChange={() => setIsTwoWay(false)}
              />
            </label>
          </div>
          <div className="form-control w-fit">
            <label className="label cursor-pointer">
              <span className="label-text pr-2 font-bold">Return</span>
              <input
                type="radio"
                name="radio-10"
                className="radio bg-white"
                checked={isTwoWay}
                onChange={() => setIsTwoWay(true)}
              />
            </label>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <div className="flex flex-col w-full">
            <InputControl
              type="text"
              label="Your Name"
              isRequired={true}
              inputProps={{
                ...register("fullName", { required: true }),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("fullName", e.target.value);
                },
              }}
            />
            {errors.fullName?.type === "required" && (
              <InputControllError text="Name is required" />
            )}
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col w-full">
            <InputControl
              type="text"
              label="Email"
              isRequired={true}
              inputProps={{
                ...register("email", {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                }),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("email", e.target.value);
                },
              }}
            />
            {errors.email?.type === "required" && (
              <InputControllError text="Email is required" />
            )}
            {errors.email?.type === "pattern" && (
              <InputControllError text="Email is not valid" />
            )}
          </div>
          <div className="flex flex-col w-full">
            <InputControl
              type="text"
              label="Phone"
              isRequired={true}
              inputProps={{
                ...register("phone", {
                  required: true,
                  pattern: /^(\+\d{1,3}\s?)?(\(\d{1,4}\)\s?)?[\d\\-]+\s?\d+$/,
                }),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("phone", e.target.value);
                },
              }}
            />
            {errors.phone?.type === "required" && (
              <InputControllError text="Phone is required" />
            )}
            {errors.phone?.type === "pattern" && (
              <InputControllError text="Invalid phone number" />
            )}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-2">
          <div className="flex flex-col flex-no-wrap md:flex-row gap-2 w-full">
            <div className="flex flex-col w-full">
              <DatePickerControll
                label="Pickup Date"
                inputProps={{
                  ...register("pickUpDate", { required: true }),
                  value: pickUpDate,
                }}
                isRequired={true}
                datePickerProps={{
                  minDate: currentDate,
                  // selectsStart: true,
                  maxDate: returnDate
                    ? new Date(convertToCompatibleDateString(returnDate))
                    : null,
                  selected: pickUpDate
                    ? new Date(convertToCompatibleDateString(pickUpDate))
                    : null,
                  // startDate: pickUpDate
                  //   ? Intl.DateTimeFormat("en-GB").format(new Date(pickUpDate))
                  //   : currentDate,
                  // endDate: returnDate
                  //   ? Intl.DateTimeFormat("en-GB").format(new Date(returnDate))
                  //   : null,
                  isClearable: true,
                }}
                onDateChange={(d) => {
                  setValue("pickUpDate", d?.toLocaleDateString("en-GB") || "");
                  trigger("pickUpDate");
                }}
              />
              {errors.pickUpDate?.type === "required" && (
                <InputControllError text="Pickup Date is required" />
              )}
            </div>
            <div className="flex flex-col w-full">
              <InputControl
                label="Pickup Time"
                type="time"
                isRequired={true}
                inputProps={{
                  ...register("pickUpTime", { required: true }),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue("pickUpTime", e.target.value);
                  },
                }}
              />
              {errors.pickUpTime?.type === "required" && (
                <InputControllError text="Pickup Time is required" />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="flex flex-col w-full">
            <InputControl
              type="text"
              label="Pickup Point"
              isRequired={true}
              inputProps={{
                ...register("pickUpPickUpPoint", { required: true }),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("pickUpPickUpPoint", e.target.value);
                },
              }}
            />
            {errors.pickUpPickUpPoint?.type === "required" && (
              <InputControllError text="Pickup Point is required" />
            )}
          </div>
          <div className="flex flex-col w-full">
            <InputControl
              type="text"
              label="Dropoff Point"
              isRequired={true}
              inputProps={{
                ...register("pickUpDropOffPoint", { required: true }),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("pickUpDropOffPoint", e.target.value);
                },
              }}
            />
            {errors.pickUpDropOffPoint?.type === "required" && (
              <InputControllError text="Dropoff Point is required" />
            )}
          </div>
        </div>
        {isTwoWay && (
          <>
            <div className="flex flex-col lg:flex-row gap-2">
              <div className="flex flex-col flex-no-wrap md:flex-row gap-2 w-full">
                <div className="flex flex-col w-full">
                  <DatePickerControll
                    label="Return Date"
                    inputProps={{
                      ...register("returnDate", { required: true }),
                    }}
                    isRequired={true}
                    datePickerProps={{
                      minDate: pickUpDate
                        ? new Date(convertToCompatibleDateString(pickUpDate))
                        : currentDate,
                      maxDate: null,
                      // selectsEnd: true,
                      selected: returnDate
                        ? new Date(convertToCompatibleDateString(returnDate))
                        : null,
                      startDate: pickUpDate
                        ? new Date(pickUpDate)
                        : currentDate,
                      endDate: returnDate
                        ? new Date(convertToCompatibleDateString(returnDate))
                        : null,
                      isClearable: true,
                    }}
                    onDateChange={(d) => {
                      setValue("returnDate", d?.toLocaleDateString("en-GB"));
                      trigger("returnDate");
                    }}
                  />
                  {errors.returnDate?.type === "required" && (
                    <InputControllError text="Return Date is required" />
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <InputControl
                    label="Return Time"
                    type="time"
                    isRequired={true}
                    inputProps={{
                      ...register("returnTime", { required: true }),
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue("returnTime", e.target.value);
                      },
                    }}
                  />
                  {errors.returnTime?.type === "required" && (
                    <InputControllError text="Return Time is required" />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-2">
              <div className="flex flex-col w-full">
                <InputControl
                  type="text"
                  label="Pickup Point"
                  isRequired={true}
                  inputProps={{
                    ...register("returnPickUpPoint", { required: true }),
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setValue("returnPickUpPoint", e.target.value);
                    },
                  }}
                />
                {errors.returnPickUpPoint?.type === "required" && (
                  <InputControllError text="Pickup Point is required" />
                )}
              </div>
              <div className="flex flex-col w-full">
                <InputControl
                  type="text"
                  label="Dropoff Point"
                  isRequired={true}
                  inputProps={{
                    ...register("returnDropOffPoint", { required: true }),
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setValue("returnDropOffPoint", e.target.value);
                    },
                  }}
                />
                {errors.returnDropOffPoint?.type === "required" && (
                  <InputControllError text="Dropoff Point is required" />
                )}
              </div>
            </div>
          </>
        )}
        <div className="flex flex-row gap-2">
          <div className="form-control w-full flex-col">
            <label className="label">
              <span className="label-text text-stone-700 font-bold">Notes</span>
            </label>
            <textarea
              style={{ maxHeight: "200px" }}
              className="textarea textarea-bordered border-2 border-stone-700 h-24 w-full text-stone-700 font-bold rounded-md"
              rows={10}
              placeholder="Notes"
              {...register("notes", { maxLength: 500 })}
            ></textarea>
            {errors.notes?.type === "maxLength" && (
              <div>
                <InputControllError text="Notes are too long" />
              </div>
            )}
          </div>
        </div>

        <input
          type="submit"
          className={`btn w-full my-3 hover:text-white rounded-full ${
            !isValid ? "btn-disabled text-slate-300" : "bg-stone-700 text-white"
          } ${
            isFormSubmitting &&
            "btn-disabled loading loading-spinner bg-slate-200 text-slate-300"
          }`}
          value={isFormSubmitting ? "Submitting…" : "Book now"}
        ></input>
      </form>
    </div>
  );
};

export default BookingPage;
