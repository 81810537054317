import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface BookNowButtonProps {
  text?: string;
}

const BookNowButton: FunctionComponent<BookNowButtonProps> = (props) => {
  return (
    <Link to="/booking">
      <button className="btn-outline border-2 btn-lg uppercase text-white font-bold border rounded-full">
        {props.text || "Book Now"}
      </button>
    </Link>
  );
};

export default BookNowButton;
