import { FunctionComponent, HTMLInputTypeAttribute } from "react";
import InputLabel from "./InputLabel";

interface InputControlProps {
  label: string;
  type: HTMLInputTypeAttribute;
  name?: string;
  placeholder?: string;
  isRequired: boolean;
  value?: string | ReadonlyArray<string> | number | undefined;
  readonly?: boolean;
  inputProps?: any;
}

const InputControl: FunctionComponent<InputControlProps> = (props) => {
  const lang =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return (
    <div className="form-control w-full font-bold">
      <InputLabel label={props.label} isRequired={props.isRequired} />
      <input
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        className="input input-bordered w-full border-stone-700 border-2 rounded-full"
        readOnly={props.readonly || false}
        lang={lang}
        {...props.inputProps}
      />
    </div>
  );
};

export default InputControl;
