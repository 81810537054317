import { ButtonHTMLAttributes, FunctionComponent, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import InputControl from "./InputControl";
import React from "react";

interface DatePickerControllProps {
  label: string;
  name?: string;
  placeholder?: string;
  isRequired: boolean;
  onDateChange?: (date: Date | null) => void;
  datePickerProps?: any;
  inputProps?: any;
}

const DatePickerControll: FunctionComponent<DatePickerControllProps> = (
  props
) => {
  // const [startDate, setStartDate] = useState<Date | null>(new Date());

  const CustomInput = (
    inputProps: React.DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <button onClick={inputProps.onClick} type="button" className="w-full">
        <InputControl
          ref={inputProps.ref}
          type="text"
          label={props.label}
          name={props.name}
          isRequired={props.isRequired}
          value={inputProps.value}
          readonly={true}
          inputProps={props.inputProps}
          {...inputProps}
        />
      </button>
    );
  };

  return (
    <DatePicker
      onChange={(date) => {
        if (props.onDateChange) {
          props.onDateChange(date);
        }
      }}
      showPopperArrow={false}
      customInput={React.createElement(React.forwardRef(CustomInput))}
      {...props.datePickerProps}
    />
  );
};

export default DatePickerControll;
