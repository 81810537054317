import PageHeading from "../components/PageHeading";
import aboutUsImageSrc from "../assets/images/mirrorView.jpg";
import { NavLink } from "react-router-dom";

const AboutUsPage = () => {
  return (
    <div style={{ marginTop: "104px" }}>
      <div className="flex flex-col md:flex-row gap-8 px-3 pt-10 lg:px-36">
        <div className="text-stone-700 text-xl text-bold text-justify w-full px-2 align-middle my-auto">
          <PageHeading text="About Us" />
          Corfu Gem is a premier private transfer service located on the
          stunning island of Corfu. Our mission is to provide visitors with a
          convenient and reliable way to explore all that this tropical paradise
          has to offer. Our fleet of vehicles includes spacious SUVs, ensuring
          that you can find the perfect ride to the airport or any other
          location on the island. With competitive pricing, exceptional customer
          service, and a commitment to quality, we strive to make your island
          adventure as unforgettable as possible.{" "}
          <NavLink className="underline mr-2" to="/booking">
            Book
          </NavLink>
          your transfer or personalized trip today and let us help you discover
          the beauty of Corfu.
        </div>
        <div className="w-full mb-4 md:mb:0">
          <img
            className="h-auto w-full rounded"
            src={aboutUsImageSrc}
            alt="Man driving a car"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
