import { FunctionComponent } from "react";

interface PageHeadingProps {
  text: string;
}

const PageHeading: FunctionComponent<PageHeadingProps> = (p) => {
  return (
    <h1 className="flex justify-left text-3xl font-bold py-3 text-stone-700">
      {p.text}
    </h1>
  );
};

export default PageHeading;
