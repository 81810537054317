import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import logoSrc from "../assets/images/Artboard 13.png";

const AppLogo: FunctionComponent = () => {
  return (
    <NavLink
      className="btn bg-transparent hover:bg-transparent uppercase p-0 md:p-1 text-normal md:text-xl border-0 h-fit"
      to="/"
    >
      <img
        src={logoSrc}
        className="h-28 w-28 md:h-48 md:w-48 aspect-square"
        alt="Corfu Gem Logo"
      />
    </NavLink>
  );
};

export default AppLogo;
