import { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faCircle,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import AppLogo from "./AppLogo";

const AppFooter: FunctionComponent = () => {
  return (
    <div className="navbar flex flex-col gap-4 md:gap-0 md:flex-row bg-stone-700 z-10 text-white font-bold md:px-16 md:p-10">
      <div className="flex grow">
        <AppLogo />
      </div>

      <div className="w-2/3 md:w-auto">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row w-full">
            <span className="fa-layers fa-fw fa-2x">
              <FontAwesomeIcon icon={faCircle} inverse />
              <FontAwesomeIcon
                icon={faPhone}
                transform="shrink-6"
                className="text-slate-600"
              />
            </span>
            <div className="ml-2 text-xl break-all">+30 698 208 2585</div>
          </div>
          <div className="flex flex-row w-full">
            <span className="fa-layers fa-fw fa-2x">
              <FontAwesomeIcon icon={faCircle} inverse />
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-slate-600"
                transform="shrink-6"
              />
            </span>
            <div className="ml-2 text-xl break-all">corfugem@gmail.com</div>
          </div>

          <div className="flex flex-row w-full ">
            <span className="fa-layers fa-fw fa-2x">
              <FontAwesomeIcon icon={faCircle} inverse />
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-slate-600"
                transform="shrink-6"
              />
            </span>
            <div className="ml-2 text-xl break-all">
              Benitses, Corfu, Greece
            </div>
          </div>
          {/* <a href="https://facebook.com" target="blank">
            <div className="flex flex-row w-full">
              <span className="fa-layers fa-fw fa-2x">
                <FontAwesomeIcon icon={faCircle} inverse />
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="text-slate-600"
                  transform="shrink-6"
                />
              </span>
              <div className="ml-2 md:text-xl">Facebook page</div>
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
